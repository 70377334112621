import { SanityFooterFragment } from '@data/sanity/queries/types/site'

import FooterBlock from './block'
import FooterCopyright from './copyright'

type FooterProps = SanityFooterFragment

const Footer = ({ blocks, copyright, paymentMethods }: FooterProps) => (
  <footer
    className="relative overflow-hidden bg-pageText bg-opacity-[4%] text-pageText pt-6 pb-10"
    role="contentinfo"
  >
    <div className="grid sm:grid-cols-2 lg:grid-cols-4">
      {blocks.map((block, index) => (
        <FooterBlock key={index} block={block} />
      ))}
    </div>

    <FooterCopyright
      copyright={copyright}
      paymentMethods={paymentMethods ?? []}
    />
  </footer>
)

export default Footer
