import {
  CSSProperties,
  KeyboardEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import FocusTrap from 'focus-trap-react'
import { m, Variants } from 'framer-motion'
import { useRect } from '@reach/rect'
import cx from 'classnames'

import { SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'
import { isBrowser } from '@lib/helpers'
import { getActiveLinkCount } from '@lib/navigation'
import { SiteContext } from '@lib/site'
import { StringsContext } from '@lib/strings'

import FeaturedProducts from './featured-products'
import MegaNavigationBackground from './mega-navigation-background'
import MegaNavigationBackdrop from './mega-navigation-backdrop'
import Menu from './menu'

export interface CSSPropertiesWithHeight extends CSSProperties {
  '--h': number
  '--hpx': string
}

interface MegaNavigationProps {
  items: SanityMenuFeaturedLinkFragment[]
  headerHeight: number
}

const swipeAnimation: Variants = {
  show: {
    opacity: 1,
    x: ['-1rem', '0rem'],
    transition: {
      x: {
        duration: 0.8,
        delay: 0.1,
        ease: [0.16, 1, 0.3, 1],
      },
      opacity: {
        duration: 0.2,
        delay: 0.1,
      },
    },
  },
  hide: {
    x: ['0rem', '1rem'],
    opacity: 0,
    transition: {
      x: {
        duration: 0.4,
        ease: [0.16, 1, 0.3, 1],
      },
      opacity: {
        duration: 0.1,
      },
    },
  },
}

const MegaNavigation = ({ items, headerHeight }: MegaNavigationProps) => {
  const { megaNavigation, toggleMegaNavigation } = useContext(SiteContext)
  const strings = useContext(StringsContext)

  const [hasFocus, setHasFocus] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const dropdownRef = useRef<HTMLDivElement | null>(null)
  // eslint-disable-next-line no-undefined
  const dropdownRectangle = useRect(dropdownRef ?? undefined, {
    observe: isDropdownOpen,
  })

  const isOpen = megaNavigation.isOpen
  const backgroundHeight = isOpen
    ? headerHeight + (dropdownRectangle?.height ?? 0)
    : 0

  // Update dropdown open state when closing menu
  useEffect(() => {
    if (!isOpen && isDropdownOpen) {
      setIsDropdownOpen(false)
    }
  }, [isDropdownOpen, isOpen])

  useEffect(() => {
    if (!isLoaded && isOpen) {
      setIsLoaded(true)
    }

    if (isBrowser) {
      document.body.classList.toggle('overflow-hidden', isOpen)
    }
  }, [isLoaded, isOpen])

  const dropdowns = items.filter(
    (item) => item._type === 'navDropdown' && 'dropdownItems' in item
  )

  if (dropdowns.length === 0 || !isLoaded) {
    return null
  }

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLDivElement>) => {
    if (key === 'Escape') {
      toggleMegaNavigation(false)
    }
  }

  const activeLinkCount = getActiveLinkCount(
    dropdowns,
    isOpen,
    megaNavigation.activeId
  )

  return (
    <>
      <FocusTrap
        active={isOpen && hasFocus && activeLinkCount > 0}
        focusTrapOptions={{ allowOutsideClick: true }}
      >
        <div
          className="hidden lg:block absolute top-full inset-x-0 z-30 bg-pageBG"
          onKeyDown={handleKeyDown}
          role="menu"
          tabIndex={-1}
        >
          {dropdowns.map(({ _key, featured, dropdownItems }) => {
            const isDropdownActive = isOpen && megaNavigation.activeId === _key

            const dropdownCallbackRef = (element: HTMLDivElement | null) => {
              // Set dropdown reference when current it is open & start observing it
              if (isDropdownActive && element) {
                dropdownRef.current = element

                if (!isDropdownOpen) {
                  setIsDropdownOpen(true)
                }
              }
            }

            return (
              <div
                key={_key}
                ref={dropdownCallbackRef}
                id={`meganav-${_key}`}
                className={cx(
                  'absolute top-0 inset-x-0 z-10 -mt-px overflow-hidden',
                  'transition-visibility duration-200',
                  {
                    'delay-[0s] pointer-events-auto': isDropdownActive,
                    'invisible delay-[.8s] pointer-events-none':
                      !isDropdownActive,
                  }
                )}
              >
                <div>
                  <div>
                    <m.div
                      initial="hide"
                      animate={isDropdownActive ? 'show' : 'hide'}
                      onAnimationComplete={(definition) =>
                        setHasFocus(definition === 'show')
                      }
                      variants={swipeAnimation}
                      className="grid grid-cols-2 relative px-10 pt-4 pb-12 will-change-transform"
                    >
                      <Menu
                        items={dropdownItems}
                        hasFocus={hasFocus && isDropdownActive}
                        onClick={() => toggleMegaNavigation(false)}
                        isMegaNavMenu
                      />

                      {featured && featured.length > 0 && (
                        <div className="grid grid-cols-2 gap-4 max-w-2xl relative w-full pl-8">
                          <div className="block absolute top-0 right-full w-2 text-xs uppercase tracking-widest font-semibold">
                            <span className="block overflow-hidden absolute top-1/2 left-0 whitespace-nowrap transform rotate-90 origin-top-left">
                              {strings.featuredProducts}
                            </span>
                          </div>
                          <FeaturedProducts
                            products={featured}
                            onClick={() => toggleMegaNavigation(false)}
                          />
                        </div>
                      )}
                    </m.div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </FocusTrap>

      <MegaNavigationBackground height={backgroundHeight} />
      <MegaNavigationBackdrop isOpen={isOpen} />
    </>
  )
}

export default MegaNavigation
