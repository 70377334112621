import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'

import {
  SanityFooterBlock1,
  SanityFooterBlock2,
  SanityFooterBlock3,
  SanityFooterBlock4,
} from '@data/sanity/queries/types/site'
import { serializers } from '@lib/serializers'

import Newsletter from '@blocks/newsletter'
import Menu from '@blocks/navigation/menu'
import Icon from '@components/icon'

interface FooterBlockProps {
  block:
    | SanityFooterBlock1
    | SanityFooterBlock2
    | SanityFooterBlock3
    | SanityFooterBlock4
}

const FooterBlock = ({ block }: FooterBlockProps) => {
  return (
    <div
      className={cx('flex flex-col p-8 text-left', {
        'lg:order-first': 'newsletter' in block,
        'lg:order-last': 'social' in block,
      })}
    >
      <h2 className="text-3xl font-normal leading-tight mb-4">{block.title}</h2>

      {'description' in block && block.description && (
        <BlockContent
          renderContainerOnSingleChild
          className="rc mb-5"
          blocks={block.description}
          serializers={serializers}
        />
      )}

      {'menu' in block && block.menu.items.length > 0 && (
        <Menu items={block.menu.items} isFooterMenu />
      )}

      {'newsletter' in block && (
        <Newsletter
          id={block.newsletter._key}
          klaviyoListID={block.newsletter.klaviyoListID}
          submit={block.newsletter.submit}
          successMsg={block.newsletter.successMsg}
          errorMsg={block.newsletter.errorMsg}
          terms={block.newsletter.terms}
        />
      )}

      {'social' in block && block.social && (
        <div className="flex flex-wrap mb-4 mt-2">
          {block.social.map((link) => (
            <a
              id={`footer-social-link-${link.icon.toLowerCase()}`}
              className="mr-3 last:mr-0"
              key={link._key}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                id="footer"
                name={link.icon}
                className="block pointer-events-none text-[24px]"
              />
            </a>
          ))}
        </div>
      )}
    </div>
  )
}

export default FooterBlock
