import cx from 'classnames'

import { SanityImageFragment } from '@data/sanity/queries/types/image'

import Photo from '@components/photo'

interface FooterCopyrightProps {
  copyright: string
  paymentMethods: SanityImageFragment[]
}

const FooterCopyright = ({
  copyright,
  paymentMethods,
}: FooterCopyrightProps) => {
  const hasPaymentMethods = paymentMethods.length > 0

  if (!copyright && !hasPaymentMethods) {
    return null
  }

  return (
    <div
      className={cx('flex items-center px-8', {
        'justify-end': !copyright && hasPaymentMethods,
        'justify-center sm:justify-end': copyright && !hasPaymentMethods,
        'flex-col md:flex-row-reverse justify-between':
          copyright && hasPaymentMethods,
      })}
    >
      {hasPaymentMethods && (
        <div className="flex flex-row items-center mb-6 md:mb-0">
          {paymentMethods.map((paymentMethod) => (
            <div
              key={paymentMethod.id}
              className="relative block mr-2 last-of-type:mr-0"
            >
              <Photo
                image={paymentMethod}
                forceWidth={24 * paymentMethod.dimensions.aspectRatio}
                forceHeight={24}
                hasPlaceholder={false}
              />
            </div>
          ))}
        </div>
      )}

      {copyright && <p className="text-center text-sm">{copyright}</p>}
    </div>
  )
}

export default FooterCopyright
