import { useRef, useContext, useEffect, useState, RefObject } from 'react'
import { m } from 'framer-motion'
import FocusTrap from 'focus-trap-react'
import { useIntersectionObserver } from '@asyarb/use-intersection-observer'
import { useRect } from '@reach/rect'
import cx from 'classnames'

import { SanityHeaderFragment } from '@data/sanity/queries/types/site'
import { SanityImageFragment } from '@data/sanity/queries/types/image'
import { CartContext } from '@lib/cart'
import { SiteContext } from '@lib/site'
import { StringsContext } from '@lib/strings'

import Menu from '@blocks/navigation/menu'
import MegaNavigation from '@blocks/navigation/mega-navigation'
import CartToggle from '@components/cart-toggle'
import PromoBar from '../promo-bar'
import Hamburger from './hamburger'
import Logo from './logo'

export interface HeaderSizeValues {
  height: number
  bottom: number
}

interface HeaderProps extends SanityHeaderFragment {
  isTransparent: boolean
  topObserverRef: RefObject<Element>
  logo?: SanityImageFragment
  invertedLogo?: SanityImageFragment
  onResize?: (newValues: HeaderSizeValues) => void
}

const Header = ({
  promo,
  menuDesktopLeft,
  menuDesktopRight,
  menuMobilePrimary,
  menuMobileSecondary,
  isTransparent,
  logo,
  invertedLogo,
  topObserverRef,
  onResize,
}: HeaderProps) => {
  const strings = useContext(StringsContext)
  const { megaNavigation, mobileMenu, toggleMegaNavigation, toggleMobileMenu } =
    useContext(SiteContext)
  const { toggleCart } = useContext(CartContext)

  const [isLoaded, setIsLoaded] = useState(false)

  const [isObserverVisible, setIsObserverVisible] = useState(isTransparent)
  const isInView = useIntersectionObserver({
    ref: topObserverRef,
    options: {
      triggerOnce: false,
      threshold: 1,
    },
  })
  const headerRef = useRef<HTMLDivElement>(null)
  const headerRect = useRect(headerRef)

  useEffect(
    () => setIsObserverVisible(isLoaded ? isInView : isTransparent),
    [isInView, isLoaded, isTransparent]
  )

  useEffect(() => {
    // Wait for intersection observer to load, then show header
    if (!isLoaded) {
      setTimeout(() => setIsLoaded(true), 200)
    }

    if (isLoaded && headerRect && onResize) {
      onResize({
        height: headerRect.height,
        bottom: headerRect.top + headerRect.height,
      })
    }
  }, [headerRect, isLoaded, onResize])

  return (
    <>
      <a
        href="#content"
        className="block fixed top-0 left-1/2 transform -translate-x-1/2 -translate-y-full z-90 px-2 py-1 bg-pageBG text-pageText text-xs font-semibold uppercase focus:translate-y-0 focus:outline-none"
      >
        {strings.skipToContent}
      </a>

      <PromoBar
        enabled={promo.enabled}
        display={promo.display}
        text={promo.text}
        link={promo.link}
      />

      <header
        className={cx('sticky top-0 inset-x-0 z-50 text-header-text', {
          'h-0': isTransparent,
        })}
      >
        <div ref={headerRef} className="relative">
          <div
            className={cx(
              'relative z-30 p-1 sm:p-4 md:p-5 transition-colors duration-300',
              {
                'bg-transparent': isTransparent && isObserverVisible,
                'bg-header-bg': !isTransparent,
                'bg-header-bg bg-opacity-95': !isObserverVisible,
                'text-white delay-75':
                  isTransparent && !megaNavigation?.isOpen && isObserverVisible,
              }
            )}
          >
            <div className="flex flex-row justify-between items-center w-full relative z-30 min-h-[50px]">
              {/* Mobile Header Menu */}
              <nav
                id="mobile-nav"
                className={cx('lg:hidden flex justify-end items-center')}
              >
                <FocusTrap active={mobileMenu.isOpen}>
                  <div className="flex items-center">
                    {/* Menu toggle */}
                    <button
                      onClick={() => toggleMobileMenu(!mobileMenu.isOpen)}
                      aria-expanded={mobileMenu.isOpen ? 'true' : 'false'}
                      aria-controls="mobile-nav"
                      aria-label={strings.mobileMenuLabel}
                      className={cx(
                        'lg:hidden absolute left-0 top-0 bottom-0 z-60 pl-4 pr-4 appearance-none no-underline cursor-pointer font-inherit rounded-full',
                        {
                          'text-pageText': mobileMenu.isOpen,
                          'transition-colors duration-300': isTransparent,
                        }
                      )}
                    >
                      <Hamburger isOpened={mobileMenu.isOpen} />
                    </button>

                    <m.div
                      initial="hide"
                      animate={mobileMenu.isOpen ? 'show' : 'hide'}
                      variants={{
                        show: {
                          x: '0%',
                        },
                        hide: {
                          x: '-100%',
                        },
                      }}
                      transition={{ duration: 0.8, ease: [0.16, 1, 0.3, 1] }}
                      className="fixed top-0 left-0 w-full h-screen z-50 flex flex-col bg-pageBG text-pageText max-w-[500px] sm:pl-5"
                    >
                      <div className="flex-1 flex flex-col overflow-y-scroll px-4 pb-4 pt-[calc(var(--headerBottom,10rem)+1.5rem)] no-scrollbar">
                        {!!menuMobilePrimary.items && (
                          <div>
                            <Menu
                              items={menuMobilePrimary.items}
                              onClick={() => toggleMobileMenu(false)}
                              isHeaderMobilePrimaryMenu
                            />
                          </div>
                        )}

                        {!!menuMobileSecondary.items && (
                          <div className="pt-6">
                            <Menu
                              items={menuMobileSecondary.items}
                              onClick={() => toggleMobileMenu(false)}
                              isHeaderMobileSecondaryMenu
                            />
                          </div>
                        )}
                      </div>
                    </m.div>

                    <div
                      className={cx(
                        'fixed inset-0 z-40 bg-black bg-opacity-40 pointer-events-none transition-opacity duration-150 ease-linear',
                        {
                          'opacity-0': !mobileMenu.isOpen,
                          'pointer-events-auto backdrop-filter-blur-[6px] opacity-100':
                            mobileMenu.isOpen,
                        }
                      )}
                      onClick={() => toggleMobileMenu(false)}
                      onKeyPress={() => null}
                      role="presentation"
                    />
                  </div>
                </FocusTrap>
              </nav>

              {/* Logo */}
              <Logo
                logo={logo}
                invertedLogo={invertedLogo}
                isInvertedLogo={isTransparent && isObserverVisible}
              />

              {/* Desktop Header Menu */}
              <nav className="hidden lg:flex items-center py-2">
                {!!menuDesktopLeft.items && (
                  <Menu
                    items={menuDesktopLeft.items}
                    onClick={() => toggleMegaNavigation(false)}
                    useMegaNav
                    isHeaderDesktopMenu
                  />
                )}
              </nav>

              <nav className="flex items-center">
                {!!menuDesktopRight.items && (
                  <Menu
                    className="hidden lg:flex"
                    items={menuDesktopRight.items}
                    onClick={() => toggleMegaNavigation(false)}
                    useMegaNav
                    isHeaderDesktopMenu
                  />
                )}

                <CartToggle
                  onClick={() => toggleCart(true)}
                  className="mr-2 lg:mr-1"
                  showCartCount
                  showCartIcon
                />
              </nav>
            </div>
          </div>

          <MegaNavigation
            items={[...menuDesktopLeft.items, ...menuDesktopRight.items]}
            headerHeight={headerRect?.height ?? 0}
          />
        </div>
      </header>
    </>
  )
}

export default Header
